import React from "react"
import { graphql, PageProps } from "gatsby"
import Page from "../components/common/page"
import Card from "../components/Card"

import HeroCard from "../components/HeroCard"
import HeroSection from "../components/HeroSection"
import ContactUsSection from "../components/ContactUsSection"
import HeroIcon from "../images/svg/contact/hero-icon.inline.svg"
import ContactForm from "../components/ContactForm"
import Header from "../components/header"
import Footer from "../components/footer"
import { useTranslation } from "react-i18next"

const ContactPage = (props: PageProps) => {
  const {t} = useTranslation();
  return (
    <Page title="Contact">
      <Header/>
      <div className="w-full overflow-x-hidden">
        <div className="block lg:flex lg:flex-row items-center justify-center">
          <div className="w-full lg:w-1/2">
            <div className="text-center lg:text-left">
              <div className="w-full">
                <div className="mb-3 lg:leading-snug lg:pb-14 lg:pl-28 grey gothic-bold lg:text-5xl text-3xl text-dark-grey">
                  <div className="inline lg:block text-dark-grey">
                    {t('contact_title')}{" "}
                  </div>
                  <div className="inline lg:block text-secondary">{t('contact_subtitle')}</div>
                </div>
              </div>
              <div className="flex justify-center">
                <HeroIcon />
              </div>
            </div>
          </div>

          {/* Contact form */}

          <div className="w-full flex justify-center items-center lg:w-3/4 lg:mb-5 lg:ml-10">
            <div className="w-full md:w-4/5 lg:w-8/12">
              <div className="lg:px-8 md:px-0 pt-6 pb-8 mb-4">
                <ContactForm />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer/>
    </Page>
  )
}

export default ContactPage
export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {ns: {in: ["contact-us", "common"]}, language: {eq: $language}})  {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;