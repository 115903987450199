import { graphql, Link } from "gatsby"
import React, { Component } from "react" // let's also import Component
import { useTranslation } from "react-i18next"
import SpinLoader from "../images/svg/shared/tail-spin.inline.svg"
import LanguageContext from "../utils/languageContext"
import { withTranslation } from "react-i18next"

type ContactFormFields = {
  formName: string
  fullname: string
  email: string
  phoneNumber: string
  message: string
  attachment: any
  referenceVideoLink: string
  newsletterSubscription: boolean
}

type ContactFormState = {
  fields: ContactFormFields
  errors: any
  formState: "loaded" | "loading" | "success" | "error" | "pending"
  attachmentName: string
}

class ContactForm extends Component<{}, ContactFormState> {
  form: any
  formValidator: any
  formRef: any = React.createRef()

  constructor(props) {
    super(props)

    this.state = {
      fields: {
        formName: "contact-form",
        fullname: "",
        email: "",
        phoneNumber: "",
        message: "",
        referenceVideoLink: "",
        attachment: null,
        newsletterSubscription: false,
      },
      errors: {},
      formState: "pending",
      attachmentName: "",
    }
  }

  componentDidMount() {
    import("react-form-input-validation")
      .then(({ default: formValidation }) => {
        this.formValidator = formValidation
        this.form = new this.formValidator(this)
        this.formInit()
      })
      .catch(error => console.error(error))
  }

  private formInit() {
    this.setValidationMessages()
    this.setFormRules()
    this.setFormLoadedState()
    this.form.onformsubmit = fields => {
      this.setState(prevState => ({
        formState: "loading",
      }))

      //  Ref: https://github.com/microsoft/TypeScript/issues/30584
      fetch("/", {
        method: "POST",
        // headers: { "Content-Type": "multipart/form-data" },
        body: this.buildFormData(fields),
      })
        .then(() => {
          this.submitSuccessState()
          this.scrollToTop()
          this.resetForm()
        })
        .catch(error => console.log(error))
    }
  }

  private setFormRules() {
    this.form.useRules({
      fullname: "required",
      email: "required|email",
      phoneNumber: "required|numeric|digits_between:10,14",
      message: "required",
      referenceVideoLink: "url",
      newsletterSubscription: "boolean",
    })
  }

  setFormLoadedState() {
    this.setState(prevState => ({
      formState: "loaded",
    }))
  }

  submitSuccessState() {
    this.setState(prevState => ({
      fields: {
        formName: "contact-form",
        fullname: "",
        email: "",
        phoneNumber: "",
        message: "",
        referenceVideoLink: "",
        attachment: null,
        newsletterSubscription: false,
      },
      formState: "success",
    }))
  }

  buildFormData(fields): FormData {
    fields = fields as ContactFormFields

    let formData = new FormData()
    formData.append("fullname", fields.fullname)
    formData.append("email", fields.email)
    formData.append("phoneNumber", fields.phoneNumber)
    formData.append("message", fields.message)
    formData.append("attachment", fields.attachment)
    formData.append("referenceVideoLink", fields.referenceVideoLink)
    formData.append("newsletterSubscription", fields.newsletterSubscription)
    formData.append("form-name", fields.formName)
    return formData
  }

  setValidationMessages() {
    if (LanguageContext.getInstance().getCurrentLanguage() == "en") {
      return
    }
    let messages = this.formValidator.getMessages("el")
    // Override the 'required' validation rule error message.
    messages.required = "Το πεδίο είναι υποχρεωτικό."
    messages.email = "Πρέπει να είναι μία έγκυρη διευθυνσή email."
    messages.numeric = "Πρέπει να περιέχει μόνο αριθμούς"
    messages.digits_between = "Το πλήθος τών ψηφίων δεν υποστηρίζεται."
    messages.url = "Πρέπει να είναι ένας έγκυρος σύνδεσμος."

    // Override the existing validation messages for locale 'el'
    this.formValidator.setMessages("el", messages)
    this.formValidator.useLang("el")
  }

  isFormLoaded(): boolean {
    return ["loaded", "success", "error"].includes(this.state.formState)
  }

  onChange = e => {
    switch (e.target.name) {
      case "attachment":
        this.setState(prevState => ({
          attachmentName: e.target.files[0].name,
          fields: {
            ...prevState.fields,
            attachment: e.target.files[0],
          },
        }))
        break
      case "newsletterSubscription":
        this.setState(prevState => ({
          fields: {
            ...prevState.fields,
            newsletterSubscription: e.target.checked,
          },
        }))
        break
      case "referenceVideoLink":
        this.setState(prevState => ({
          fields: {
            ...prevState.fields,
            referenceVideoLink: e.target.value,
          },
        }))
        break
    }
  }

  resetForm = () => {
    this.formRef.reset()
  }
  scrollToTop = () => {
    window.scroll({ top: 0, behavior: "smooth" })
  }

  render() {
    const { attachmentName } = this.state
    let fileName =
      attachmentName.length > 0 ? (
        <span>
          {this.props.t("chosen_file")} {attachmentName}
        </span>
      ) : (
        <span>{this.props.t("attach_a_file")} </span>
      )

    return (
      <React.Fragment>
        {this.state.formState == "success" && (
          <div className="bg-green-500 m-5 p-5 rounded-3xl text-white text-center">
            {this.props.t("thank_you_will_contact_you_soon")} 🙂
          </div>
        )}

        <form
          ref={el => (this.formRef = el)}
          data-netlify="true"
          name="contact-form"
          onSubmit={this.form && this.form.handleSubmit}
          className="contact-form px-8 pt-6 pb-8 mb-4"
        >
          <input
            type="hidden"
            name="form-full-name"
            defaultValue="contact-form"
          />
          <div className="mb-4">
            <input
              className="appearance-none border rounded w-full py-4 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              type="text"
              name="fullname"
              onBlur={
                this.isFormLoaded() ? this.form.handleBlurEvent : undefined
              }
              onChange={
                this.isFormLoaded() ? this.form.handleChangeEvent : undefined
              }
              defaultValue={this.state.fields.fullname}
              placeholder={this.props.t("full_name")}
            />
            <label className="text-red-400">
              {this.state.errors.fullname ? this.state.errors.fullname : ""}
            </label>
          </div>

          <div className="mb-4">
            <input
              className="appearance-none border rounded w-full py-4 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              type="email"
              name="email"
              onBlur={
                this.isFormLoaded() ? this.form.handleBlurEvent : undefined
              }
              onChange={
                this.isFormLoaded() ? this.form.handleChangeEvent : undefined
              }
              defaultValue={this.state.fields.email}
              placeholder={this.props.t("email")}
            />
            <label className="text-red-400">
              {this.state.errors.email ? this.state.errors.email : ""}
            </label>
          </div>

          <div className="mb-4">
            <input
              className="appearance-none border rounded w-full py-4 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              type="tel"
              name="phoneNumber"
              onBlur={
                this.isFormLoaded() ? this.form.handleBlurEvent : undefined
              }
              onChange={
                this.isFormLoaded() ? this.form.handleChangeEvent : undefined
              }
              defaultValue={this.state.fields.phoneNumber}
              placeholder={this.props.t("phone_number")}
            />
            <label className="text-red-400">
              {this.state.errors.phoneNumber
                ? this.state.errors.phoneNumber
                : ""}
            </label>
          </div>
          <div className="mb-4">
            <textarea
              className="w-full px-3 py-2 text-gray-700 border rounded-lg focus:outline-none"
              name="message"
              onBlur={
                this.isFormLoaded() ? this.form.handleBlurEvent : undefined
              }
              onChange={
                this.isFormLoaded() ? this.form.handleChangeEvent : undefined
              }
              defaultValue={this.state.fields.message}
              rows="4"
              placeholder={this.props.t("message")}
            ></textarea>
            <label className="text-red-400">
              {this.state.errors.message ? this.state.errors.message : ""}
            </label>
          </div>
          <div className="mb-4">
            <input
              className="appearance-none border rounded w-full py-4 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              type="text"
              name="referenceVideoLink"
              defaultValue={this.state.fields.referenceVideoLink}
              placeholder={this.props.t("video_example")}
              onBlur={
                this.isFormLoaded() ? this.form.handleBlurEvent : undefined
              }
              onChange={
                this.isFormLoaded() ? this.form.handleChangeEvent : undefined
              }
            />
            <label className="text-red-400">
              {this.state.errors.referenceVideoLink
                ? this.state.errors.referenceVideoLink
                : ""}
            </label>
          </div>
          <div className="mb-4">
            <label className="w-100 flex flex-col items-center px-3 py-2 bg-white text-blue rounded-lg shadow-md tracking-wide border border-blue cursor-pointer hover:bg-blue hover:text-white">
              <svg
                className="w-8 h-8"
                fill="#3b82f6"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
              >
                <path d="M16.88 9.1A4 4 0 0 1 16 17H5a5 5 0 0 1-1-9.9V7a3 3 0 0 1 4.52-2.59A4.98 4.98 0 0 1 17 8c0 .38-.04.74-.12 1.1zM11 11h3l-4-4-4 4h3v3h2v-3z" />
              </svg>
              <span className="mt-2 text-base leading-normal text-secondary">
                {fileName}
              </span>
              <input
                type="file"
                name="attachment"
                className="hidden"
                onChange={event => this.onChange(event)}
              />
            </label>
          </div>
          <div className="mb-4">
            <label className="inline-flex items-start">
              <input
                type="checkbox"
                className="form-checkbox text-secondary"
                name="newsletterSubscription"
                onChange={event => this.onChange(event)}
              />
              <span className="-mt-2 ml-2">
                <p>{this.props.t("newsletter")}</p>
                <p className="mt-3 text-sm">
                  {this.props.t("data_protection_notice")}{" "}
                  <Link className="text-secondary" to="/data-protection-notice">
                    {this.props.t("data_proctection")}
                  </Link>
                </p>
              </span>
            </label>
          </div>
          <div className="flex items-center justify-between">
            <button
              className={`bg-blue-500 hover:bg-blue-700 flex flex-row items-center text-white font-bold p-4 rounded-2xl focus:outline-none focus:shadow-outline ${
                this.state.formState == "loading" ? "disabled:opacity-50" : ""
              }`}
              type="submit"
              disabled={this.state.formState == "loading" ? true : false}
            >
              {this.props.t("submit")}
              {this.state.formState == "loading" && (
                <SpinLoader className="w-5 ml-3 animate-spin" />
              )}
            </button>
          </div>
        </form>
      </React.Fragment>
    )
  }
}
export default withTranslation("contact-us")(ContactForm)
